<template>
    <div class="footer">
        <div class="container large">
            <div class="row">
                <div class="col-lg-7 col-md-4">
                    <div class="content-wrapper">
                        <img src="../assets/images/icons/logo.png" alt="logo" class="logo">
                        <p class="large">Become a successful trader copying <br>other successful traders.</p>
                    </div>
                </div>
                <div class="col-lg-1 col-md-2 col-6">
                    <p class="title">Product</p>
                    <ul class="footer-links">
                        <li><a href="/#overview">Overview</a></li>
                        <li><a href="/#pricing">Pricing</a></li>
                        <li><a href="/#features">Features</a></li>
                        <li><a href="/#faq">FAQ</a></li>
                    </ul>
                </div>
                <div class="col-lg-1 col-md-2 col-6">
                    <p class="title">Company</p>
                    <ul class="footer-links">
                        <li><a href="#">About us</a></li>
                        <li><a href="#">Press</a></li>
                    </ul>
                </div>
                <div class="col-lg-1 col-md-2 col-6">
                    <p class="title">Social</p>
                    <ul class="footer-links">
                        <li><a href="#">Twitter</a></li>
                        <li><a href="#">Instagram</a></li>
                        <li><a href="#">Facebook</a></li>
                    </ul>
                </div>
                <div class="col-lg-2 col-md-2 col-6">
                    <p class="title">Legal</p>
                    <ul class="footer-links">
                        <li><router-link to="#">Terms of service</router-link></li>
                        <li><router-link to="#">Privacy policy</router-link></li>
                        <li><router-link to="#">Cookie policy</router-link></li>
                    </ul>
                </div>
            </div>
            <div class="footer-bottom">
                <p>Copyright © 2023 <router-link to="/">Cryptotrading</router-link></p>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name:'FooterComponent',
}
</script>
