<template>
    <div class="leaderboard">
        <Header></Header>

        <section class="leaderlist" data-aos="fade-up">
            <div class="container small">
                <div class="row">

                    <h1 class="text-center title">Best performing traders<br> on Leaderboard</h1>
                    <div class="col-sm-4" v-for="leader in leaderboard" :key="leader.trader">
                        <div class="traderbox">
                            <h4>{{ leader.trader }}</h4>
                            <p class="smaller">{{ leader.followers }} followers</p>

                            <div class="row">
                                <div class="col-6">
                                    <ul>
                                        <li>Daily ROI: <b>0%</b></li>
                                        <li>Weekly ROI: <b>{{ leader.weekly_roi }}%</b></li>
                                    </ul>
                                </div>

                                <div class="col-6">
                                    <ul>
                                        <li>Monthly ROI: <b>{{ leader.monthly_roi }}</b></li>
                                        <li>All ROI: <b>{{ leader.all_roi }}</b></li>
                                    </ul>
                                </div>
                            </div>

                            <div :class="getAlertClass(leader.activetrades)" role="alert">
                                {{ leader.activetrades }} trade in progress
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>

        <Footer></Footer>
    </div>
</template>

<script>

import Header from '../components/HeaderComponent'
import Footer from '../components/FooterComponent'
import axios from 'axios'

export default {
  name: 'HomeView',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
        leaderboard: [],
    }
  },
  beforeMount(){
    this.getLeaderboard()
  },
  methods:{
    getLeaderboard() {
      var token =  localStorage.getItem("session"); 
      axios.post(this.$api+'/getLeaderboard.php', {
        token: token,
      })
      .then((response) => {
        console.log(response.data)
        this.leaderboard = response.data
      });
    },
    getAlertClass(active) {
        if(active > 0)
            return 'alert alert-primary'
        else
            return 'alert alert-empty'

    }
  }
}
</script>