<template>
    <div class="contact">
        <Header></Header>

        <section class="waybox" data-aos="fade-up">
            <div class="container small">
                <div class="row">
                    <h1 class="text-center title mb-5">Get in touch with us</h1>

                    <div class="col-sm-4">
                        <div class="graybox">
                            <h4>General & Business</h4>
                            
                            <p>Detailed proposals and general inquiries.</p>
                            <div class="action">
                                <p>hello@tradepicker.net</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <div class="graybox">
                            <h4>Live chat</h4>
                            
                            <p>Quick help for questions and account configuration. </p>
                            <div class="action">
                                <button class="btn btn-style">Open</button>
                            </div>
                        </div>
                    </div>

                    <div class="col-sm-4">
                        <div class="graybox">
                            <h4>Ticket</h4>
                            
                            <p>Recommended for issues connected to your account.</p>
                            <div class="action">
                                <button class="btn btn-style">Open</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <Footer></Footer>
    </div>
</template>

<script>

import Header from '../components/HeaderComponent'
import Footer from '../components/FooterComponent'

export default {
  name: 'BlogView',
  components: {
    Header,
    Footer,
  },
  mounted(){

  },
  methods:{

  }
}
</script>