<template>
    <div class="faq">
        <div class="container small" data-aos="fade-up">
            <div class="heading">
            <h3>Frequently <span>asked questions</span></h3>
            <p>Manage your trades and define your own <br> strategy through custom settings that fit better <br> your trading budget.</p>
        </div>
        <div class="faq-list-wrapper">
            <ul class="faq-list">
                <li v-for="list in lists" :key="list.id">
                    <div class="icon">
                        <img src="../assets/images/icons/thumb.svg" alt="accordion-ion">
                    </div>
                    <div class="content">
                        <p class="large">{{list.question}}</p>
                        <p class="p">{{list.answer}}</p>
                    </div>
                </li>
            </ul>
        </div>
        </div>
    </div>
</template>

<script>
export default{
    name:'FAQComponent',
    data () {
         return {
            lists: [
               {
                question: 'Is a free trial available?',
               answer:'Although we guarantee a 3 days money-back policy, we do not issue free trials.'
            },
            {
                question: 'How much can I earn with your platform?',
               answer:'Your profit strongly depends on the trader you copy, choose it wisely.'
            },
            {
                question: 'What security measures does your platform apply?',
               answer:'Important data such as API key is securely encrypted before being stored into our database.'
            },
            {
                question: 'Can you add my favourite traders to your list?',
               answer:'Yes, we can add any trader of your choice if he is displayed on Binance Leaderboard.'
            },
            {
                question: 'Is there any referral marketing opportunity?',
               answer:'We do offer an affiliate program for everyone which provides you up to 20% of the income you bring in.'
            },
            {
                question: 'What if I want to copy multiple traders at once?',
               answer:'Currently it is not possible to follow more than one trader per time as it would cause conflicts between positions.'
            },
            ]
         }
      },
}
</script>
