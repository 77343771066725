<template>
    <div id="overview" class="ultra-accurate">
        <div class="container small" data-aos="zoom-in">
            <div class="heading text-center">
                <h3>Ultra accurate <span>trading solution</span></h3>
                <p>Rapid web scraping to ensure perfect entries <br/> and match every single action of the trader.</p>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-3 align-self-center">
                    <ul class="ultra-accurate-link left">
                        <li>Monitors the coin price until the entry is close enough based on our rules.</li>
                        <li>Market close order as soon as the trader closes the position.</li>
                        <li>Never miss a pump with our <br/>fast scraping system.</li>
                    </ul>
                </div>
                <div class="col-lg-4 col-md-6 align-self-center">
                    <div class="img-wrapper">
                        <img src="../assets/images/ultra-accurate-img-new.png" alt="ultra-accurate-img">
                    </div>
                </div>
                <div class="col-lg-4 col-md-3 align-self-center">
                    <ul class="ultra-accurate-link right">
                        <li>Configure your account with your own custom settings to reach your goals.</li>
                        <li>Secure and encrypted API system with disabled withdrawals.</li>
                        <li>Build your own solutions with our API or receiving signals notifications.</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:'UltraAccurate'
}
</script>
