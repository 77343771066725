<template>
    <div class="header">
        <div class="container large">
            <div class="row">
                <div class="col-lg-3 col-md-12 align-self-center">
                    <div class="logo-wrapper">
                        <router-link to="/">       <img src="../assets/images/logo.png" alt="logo" class="logo"></router-link>
                        <img src="../assets/images/icons/hamburger.png" alt="hamburger" class="hamburger" @click="showMenu()">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6 align-self-center" >
                    <div class="header-menu-wrapper" :class="{active: isActive}">
                        <img src="../assets/images/icons/close.png" alt="close" @click="closeMenu()" class="close-menu" :class="{active: isActive}">
                    <ul class="header-menu" >
                        <li><a @click="closeMenu()" href="/#features">Features</a></li>
                        <li><a @click="closeMenu()" href="/#pricing">Pricing</a></li>
                        <li><router-link @click="closeMenu()" to="/leaderboard">Leaderboard</router-link></li>
                        <li class="dropdown-link"><a href="#" @click="onActiveMenu1()">News</a>
                          <ul class="dropdown-ul" :class="{'active' : isMenu1}">
                            <li><router-link to="/news">Market updates</router-link></li>
                            <li><a href="/news">Educational</a></li>
                          </ul>
                        </li>
                        <li class="dropdown-link"><a href="#" @click="onActiveMenu2()">Help</a>
                          <ul class="dropdown-ul" :class="{'active' : isMenu2}">
                            <li><router-link to="/contact">Get in touch</router-link></li>
                            <li><router-link to="/contact">Business inquiry</router-link></li>
                          </ul>
                        </li>
                    </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 align-self-center text-end">
                    <div class="login-btn-wrapper">
                        <a href="https://panel.tradepicker.net/login" class="login-btn">Login</a>
                        <a href="https://panel.tradepicker.net/signup" class="sign-up-btn btn-style">Sign up</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name:'HeaderComponent',
    components: {

  },
  data() {
    return {
      isActive: false,
      isMenu1: false,
      isMenu2: false,
    };
  },
  mounted(){

  },
  methods: {
    onActiveMenu1(){
      this.isMenu1 = !this.isMenu1;
      if (this.isMenu2 === true){
        this.isMenu2 = false;
      }
    },
    onActiveMenu2(){
      this.isMenu2 = !this.isMenu2;
      if (this.isMenu1 === true){
        this.isMenu1 = false;
      }
    },
    showMenu: function() {
      this.isActive = true;
    },
    closeMenu: function() {
      this.isActive = false;
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
