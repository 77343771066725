import { createWebHistory, createRouter } from "vue-router";
import Home from "@/views/HomeView.vue";
import Leaderboard from "@/views/LeaderboardView.vue";
import Blog from "@/views/BlogView.vue";
import Article from "@/views/ArticleView.vue";
import Contact from "@/views/ContactView.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/leaderboard",
        name: "Leaderboard",
        component: Leaderboard,
    },
    {
        path: "/news",
        name: "News",
        component: Blog,
    },
    {
        path: "/news/:slug",
        name: "Article",
        component: Article,
    },
    {
        path: "/contact",
        name: "Contact",
        component: Contact,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
