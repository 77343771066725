<template>
    <div id="pricing" class="pricing">
        <div class="container small" data-aos="zoom-in">
            <div class="heading text-center">
                <h2>Pricing</h2>
                <p class="large">Choose the plan that fits your <br> needs better.</p>
            </div>
            <div class="tabs-wrapper">

                <div class="text-center">
                    <ul class="nav nav-tabs">
                <li @click="updatePrice(1)" v-bind:class="[ activeTab === '1' ? 'active' : '' ]" class="my-tabs"><span>Monthly</span><a data-toggle="tab" href="#" ></a></li>
                <li @click="updatePrice(3)" v-bind:class="[ activeTab === '2' ? 'active' : '' ]" class="my-tabs"><span>Quarterly</span></li>
                <li @click="updatePrice(12)" v-bind:class="[ activeTab === '3' ? 'active' : '' ]" class="my-tabs"><span>Annaually</span></li>
              </ul>
                </div>
              <div class="nav-tabs-div" >
                <div class="row">
                    <div class="col-md-6" >
                        <div class="main-wrapper-1">
                           <div class="content-wrapper">
                            <strong>Autopilot</strong>
                            <p class="text">Copy our pre-selected trader directly into your exchange account.</p>
                            <h3>${{ priceAP }}<p>/{{ duration }}</p></h3>
                            <ul class="features">
                                <li><img src="../assets/images/icons/single-user-feature-icon.png" alt=""> Copy a trader automatically selected by our system.</li>
                                <li class="mt-8"><img src="../assets/images/icons/feature-cross-icon.png" alt="">Cannot choose the trader to copy</li>
                            </ul>
                           </div>
                           <a href="https://panel.tradepicker.net/signup?id=1" class="btn-style disable sec">Get started</a>
                        </div>
                    </div>
                    <div class="col-md-6" >
                        <div class="main-wrapper-1 active">
                           <div class="content-wrapper">
                            <strong>CopyTrading</strong>
                            <p class="text">Copy any trader you want from Leaderboard directly into your exchange account.</p>
                            <h3>${{ pricePP }}<p>/{{ duration }}</p></h3>
                            <ul class="features">
                                <li><img src="../assets/images/icons/single-user-feature-icon.png" alt=""> All other plans features included</li>
                                <li><img src="../assets/images/icons/double-user-feature-icon.png" alt="">Choose your favourite trader from official Binance Leaderboard.</li>
                            </ul>
                           </div>
                           <a href="https://panel.tradepicker.net/signup?id=1" class="btn-style disable">Get started</a>
                        </div>
                    </div>
                </div>
                <div class="signals-wrapper">
                    <div class="signals-content">
                        <div class="text">
                            <p>Signals</p>
                            <strong>${{ priceS }}<span>/{{ duration }}</span></strong>
                        </div>
                        <p>Receive entry and exit notifications directly on your telegram.</p>
                        <a href="https://panel.tradepicker.net/signup?id=1" class="btn-style disable">Get started</a>
                    </div>
                    <div class="signals-features-list">
                        <ul class="features">
                            <li><img src="../assets/images/icons/single-user-feature-icon.png" alt="icon"><span>Connect to our telegram bot and get entry, dca and exit.</span></li>
                            <li><img src="../assets/images/icons/double-user-feature-icon.png" alt="icon"><span>Choose your favourite trader from official Binance Leaderboard.</span></li>
                            <li><img src="../assets/images/icons/feature-cross-icon.png" alt="icon"><span>No automated copy-trading</span></li>
                        </ul>
                    </div>
                </div>
              </div>

            </div>
        </div>
    </div>
</template>

<script>
export default{
    name:'PricingComponent',
    data(){
    return{
        activeTab: '1',
        isActive1: false,
        isActive2: false,
        priceAP: 12,
        pricePP: 24,
        priceS: 5,
        duration: 'month',
    }
  },
  methods: {
    updatePrice(length) {
        if(length == 1) {
            this.priceAP = 12
            this.pricePP = 24
            this.priceS = 5
            this.activeTab = '1'
            this.duration = 'month'
        }
        else if(length == 3) {
            this.priceAP = 28
            this.pricePP = 49
            this.priceS = 12
            this.activeTab = '2'
            this.duration = 'quarter'
        }
        else if(length == 12) {
            this.priceAP = 89
            this.pricePP = 149
            this.priceS = 40
            this.activeTab = '3'
            this.duration = 'year'
        }
    }
  },
}
</script>
