<template>
  <div class="get-started">
    <div class="container small" data-aos="fade-up">
      <div class="row">
        <div class="col-lg-6 col-md-6 align-self-center">
          <div class="simple-way-content">
            <img src="../assets/images/logo.png" alt="logo" class="logo">
            <h3>A simple way <br> to trade profitably</h3>
            <p class="large">While the majority of newbies lose money <br> trading, you have a chance to copy the most <br> successful and best-performing traders.</p>
          </div>
        </div>
        <div class="col-lg-2 col-md-2 d-md-none d-lg-block"></div>
        <div class="col-lg-4 col-md-6 align-self-center">
          <div class="accordion-wrapper">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingOne">
                  <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                    <img src="../assets/images/icons/accordion-heading-icon.svg" alt="accordion-heading-icon" class="logo">
                    Getting started
                  </button>
                </h2>
                <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>Create an account and subscribe to one of our plans to start copying any trader of your choice.</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    <img src="../assets/images/icons/accordion-heading-icon.svg" alt="accordion-heading-icon" class="logo">
                    Setting up your keys
                  </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>Easily create and configure the API keys into your account.</p>
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                    <img src="../assets/images/icons/accordion-heading-icon.svg" alt="accordion-heading-icon" class="logo">
                    Copy trade
                  </button>
                </h2>
                <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                    <p>Choose a trader and start copying his trades automatically.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GetStarted',
}
</script>
