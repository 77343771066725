<template>
    <div class="article">
        <Header></Header>

        <section class="content" data-aos="fade-up">
          <div class="container small">
            <div class="row">
              <h1 class="text-center">{{ article.title }}</h1>

              <div class="mt-5" v-html="article.content"></div>
            </div>
          </div>
        </section>

        <Footer></Footer>
    </div>
</template>

<script>

import Header from '../components/HeaderComponent'
import Footer from '../components/FooterComponent'
import axios from 'axios'

export default {
  name: 'BlogView',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
        article: [],
    }
  },
  beforeMount(){
    this.getArticle()
  },
  methods:{
    getArticle() {
        axios.get('https://blog.behindthis.app/api/tradepicker/XAAXt2h!35Nj/article/'+this.$route.params.slug)
        .then((response) => {
            console.log(response.data)
            this.article = response.data
        });
    },
  }
}
</script>