<template>
    <div class="testimonial">
        <div class="container small" data-aos="fade-up">
            <div class="heading text-center">
                <h3>Clients say <span>about us</span></h3>
            </div>
            <div class="testimonial-slider">
                <carousel :items-to-show="3" :wrapAround="true" :transition="400"  :breakpoints="breakpoints">
                    <slide v-for="review in reviews" :key="review.id">
                        <div class="container-fluid">
          <div class="item">
            <div class="slide-wrapper">
              <p class="large">{{ review.name }}</p>
              <img src="../assets/images/icons/rating-starts-img.png" alt="rating-starts-img">
              <p class="p">{{ review.comments }}</p>
            </div>
          </div>
        </div>
    </slide>
    <template #addons>
      <navigation />
      <pagination />
    </template>
  </carousel>

            </div>
        </div>
    </div>
</template>

<script>

import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'

export default{
    name:'TestimonialComponent',

    data () {
         return {
            reviews: [
               {
                name: 'Gerald Tellez',
               comments:'It has been a great pleasure for me to be able to copy TreeOfAlpha and generate profits with his winning strategies.'
            },
            {
              name: 'Eliot Harquin',
                comments:'So far it’s working fine for me and the trader I am currently copying has a big winning streak.'
            },
            {
              name: 'Kristian Mueller',
                comments:'Finally a tool that makes it easier for me to receive signals from binance top traders.'
            },
            {
                name: 'Gerald Tellez',
               comments:'It has been a great pleasure for me to be able to copy TreeOfAlpha and generate profits with his winning strategies.'
            },
            {
              name: 'Eliot Harquin',
                comments:'So far it’s working fine for me and the trader I am currently copying has a big winning streak.'
            },
            {
              name: 'Kristian Mueller',
                comments:'Finally a tool that makes it easier for me to receive signals from binance top traders.'
            },
            ],
                breakpoints: {
      // 700px and up
      200: {
        itemsToShow: 1,
      },
      // 1024 and up
      768: {
        itemsToShow: 3,
        // snapAlign: 'center',
      },
      991:{
        itemsToShow: 3,
      }
    },

         }
      },

    components:{
        Carousel,
    Slide,
    Navigation,
    }
}
</script>
