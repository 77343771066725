<template>
  <div class="homePage">
    <Header></Header>
    <div class="banner-section" data-aos="fade-up">
      <div class="container">
        <div class="banner-content">
          <h1>Copy the best <br> traders of Binance</h1>
          <p class="large">A real fastlane to become a successful trader  <br> without any knowledge.</p>
          <div class="btn-wrapper">
            <a href="https://panel.tradepicker.net/signup" class="btn-style medium">Sign up</a>
            <a href="#" class="btn-style transparent medium">Get in touch</a>
          </div>
          <img src="../assets/images/banner-top-bg-image.png" alt="banner-top-bg-img" class="banner-top-bg-img">
          <img src="../assets/images/banner-bottom-bg-img.png" alt="banner-bottom-bg-img" class="banner-bottom-bg-img">
          <ul class="banner-icons">
            <li><img src="../assets/images/icons/banner-icon1.png" alt="banner-icon1"></li>
            <li><img src="../assets/images/icons/banner-icon2.png" alt="banner-icon2"></li>
          </ul>
      </div>
      </div>

    </div>
    <GetStarted></GetStarted>
    <Pricing></Pricing>
    <FullyAutomated></FullyAutomated>
    <UltraAccurate></UltraAccurate>
    <FAQ></FAQ>
    <Testimonial></Testimonial>
    <Footer></Footer>
  </div>

</template>


<script>
// @ is an alias to /src

import Header from '../components/HeaderComponent'
import GetStarted from '../components/GetStarted'
import Pricing from '../components/PricingComponent'
import FullyAutomated from '../components/FullyAutomated'
import FAQ from '../components/FAQComponent'
import Testimonial from '../components/TestimonialComponent'
import Footer from '../components/FooterComponent'
import UltraAccurate from '../components/UltraAccurate'

export default {
  name: 'HomeView',
  components: {
    Header,
    GetStarted,
    Pricing,
    FullyAutomated,
    FAQ,
    Testimonial,
    Footer,
    UltraAccurate,
  },
  mounted(){

  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>

</style>
