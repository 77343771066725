<template>
    <div id="features" class="fully-automated">
        <div class="container medium" data-aos="fade-up">
            <div class="row">
                <div class="col-lg-4 col-md-6 align-self-center">
                    <div class="img-wrapper">
                        <img src="../assets/images/fully-automated.png" alt="fully-automated">
                    </div>
                </div>
                <div class="col-lg-3 align-self-center"></div>
                <div class="col-lg-5 col-md-6 align-self-center" >
                    <div class="content-wrapper">
                        <h3>Fully automated <span>web platform</span></h3>
                        <p>Manage your trades and define your own strategy through custom settings that fit better your trading budget.</p>
                        <ul class="adjust-list">
                            <li>Adjust the risk on your own</li>
                            <li>Matching trader original leverage or set a fixed one</li>
                            <li>Use a fixed amount per trade or defin a percentage of trader’s original size.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    name:'FullyAutomated'
}
</script>
