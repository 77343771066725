<template>
    <div class="blog">
        
        <Header></Header>

        <section class="bigpicture" data-aos="fade-up">
            <div class="container small">
                <div class="row">

                    <h1 class="text-center title mb-5">Our latest news</h1>

                    <div class="col-sm-8">
                        <div class="imgbox" v-bind:style="{ backgroundImage: 'url(' + articles[0].image + ')' }">
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="imgbox">
                            <h4>{{ articles[0].title }}</h4>

                            <p>{{ this.removeTags(articles[0].content) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="list" data-aos="fade-up">
            <div class="container small">
                <div class="row">
                    <div class="col-sm-4 mb-3" v-for="article in articles" :key="article.id">
                        <router-link :to="`/news/${article.title}`">
                            <div class="imgbox" v-bind:style="{ backgroundImage: 'url(' + article.image + ')' }">
                            </div>

                            <h4>{{ this.removeSlash(article.title) }}</h4>
                        </router-link>
                    </div>
                </div>
            </div>
        </section>

        <Footer></Footer>
    </div>
</template>

<script>

import Header from '../components/HeaderComponent'
import Footer from '../components/FooterComponent'
import axios from 'axios'

export default {
  name: 'BlogView',
  components: {
    Header,
    Footer,
  },
  data() {
    return {
        articles: [],
    }
  },
  beforeMount(){
    this.getArticles()
  },
  methods:{
    getArticles() {
        axios.get('https://blog.behindthis.app/api/tradepicker/XAAXt2h!35Nj/article/list')
        .then((response) => {
            console.log(response.data)
            this.articles = response.data
        });
    },
    removeTags(str) {
        if ((str===null) || (str===''))
            return false;
        else
            str = str.toString();
    
        return str.replace( /(<([^>]+)>)/ig, '');
    },
    removeSlash(str)  {
        return str.replace('-', ' ');
    }
  }
}
</script>